<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="
        weekendId ? $t('salon-days-off.breadcrumbs-update-label') : $t('salon-days-off.breadcrumbs-add-label')
      "
      :has-back="true"
      :button-back-link="'/salon/days-off'"
      :class="
        'd-flex justify-content-between align-items-center border-bottom px-1-5 py-0-8 position-fixed bg-white w-100'
      "
      style="z-index: 1001;"
    >
      <template v-slot:action>
        <div class="d-flex align-items-center">
          <button v-if="weekendId" class="btn btn-checkbox px-1-5 py-0-8 mr-1-5" @click="deleteWeekend()">
            <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

            <span class="font-weight-regular roboto-font text-black">
              {{ $t('common.delete-btn') }}
            </span>
          </button>
          <button :disabled="submitProcess" class="btn btn-primary px-4 py-1" @click="submitForm()">
            <span class="font-weight-medium poppins-font">
              {{ $t('common.submit-btn') }}
            </span>
          </button>
        </div>
      </template>
    </PageNavigator>

    <div class="px-1-5 pt-7-5 full-page-height">
      <div class="h-100">
        <form>
          <div class="d-flex">
            <div class="col-md-6 col-lg-8 pl-0">
              <label for="title" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                {{ $t('salon-days-off.form-label.title') }}
              </label>
              <div class="input-group col-12 px-0 mb-1-5">
                <input
                  :id="'title'"
                  v-model="formFields.title"
                  v-validate="fieldValidationRule + '|max:100'"
                  :data-vv-as="$t('salon-days-off.form-label.title')"
                  type="text"
                  class="form-control w-100 px-1-5"
                  :name="'title'"
                  :placeholder="`${$t('salon-days-off.title_placeholder')}`"
                />
              </div>

              <span v-if="errors.has('title')" class="error-message mt-0-5 mb-1-5">{{ errors.first('title') }}</span>
            </div>
            <div class="col-lg-4 col-md-6 pr-0">
              <label for="staff" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                {{ $t('salon-days-off.form-label.appointment') }}
              </label>
              <div class="row">
                <div class="col-12 mb-1-5">
                  <select
                    v-model="formFields.staff"
                    name="staff"
                    class="form-control w-100 px-0-5 text-center default-appearance"
                    style="font-size:14px;"
                    @change="choosenStaff($event)"
                  >
                    <option value="0">{{ $t('salon-days-off.all_salon') }}</option>
                    <option
                      v-for="(employee, key) in staffList"
                      :key="`staff_${key}`"
                      :value="employee.staff_id ? employee.staff_id : employee.manager_id"
                      >{{ employee.fullName }}</option
                    >
                  </select>
                </div>
              </div>

              <span v-if="errors.has('staff')" class="error-message mt-0-5 mb-1-5">{{ errors.first('staff') }}</span>
            </div>
          </div>
          <div class="activeWeekend">
            <div class="checkbox mb-1-5">
              <label class="mb-0">
                <input v-model="activatedFullDay" type="checkbox" class="activateWeekend" />
                <i class="input-helper"></i>
                <div class="text-black text-small poppins-font" style="line-height: 24px;">
                  {{ $t('salon-days-off.form-label.day_day') }}
                </div>
              </label>
            </div>
            <div class="col-lg-8 col-md-12 pl-0 mb-1-5">
              <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                {{ $t('salon-days-off.form-label.date') }}
              </label>
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap align-items-start">
                  <div class="input-group mr-0-5 position-relative">
                    <select
                      v-model="formFields.date"
                      v-validate="fieldValidationRule"
                      :data-vv-as="$t('salon-days-off.form-label.date')"
                      name="date"
                      class="form-control w-100 px-0-5 text-center"
                      style="font-size:14px;"
                    >
                      <option v-for="(option, key) in dateList" :key="`date_${key}`" :value="option">{{
                        option
                      }}</option>
                    </select>
                  </div>

                  <span v-if="errors.has('date')" class="error-message mt-0-5 mb-1-5 w-100">
                    {{ errors.first('date') }}
                  </span>
                </div>

                <div class="d-flex flex-wrap ml-2 mr-auto">
                  <div class="input-group mr-0-5 position-relative small-input">
                    <select
                      v-model="formFields.start_hours"
                      v-validate="!activatedFullDay ? fieldValidationRule : ''"
                      :data-vv-as="$t('salon-days-off.form-label.date')"
                      :disabled="activatedFullDay"
                      name="start_hours"
                      class="form-control w-100 px-0-5 text-center"
                      style="font-size:14px;"
                    >
                      <option v-for="(option, key) in startHoursList" :key="`start_hours_${key}`" :value="option">{{
                        option
                      }}</option>
                    </select>
                  </div>
                  <div class="input-group mr-0-5 position-relative small-input">
                    <select
                      v-model="formFields.start_minutes"
                      v-validate="!activatedFullDay ? fieldValidationRule : ''"
                      :data-vv-as="$t('salon-days-off.form-label.date')"
                      :disabled="activatedFullDay"
                      name="start_minutes"
                      class="form-control w-100 px-0-5 text-center"
                      style="font-size:14px;"
                    >
                      <option v-for="(option, key) in startMinutetsList" :key="`start_minutes${key}`" :value="option">{{
                        option
                      }}</option>
                    </select>
                  </div>

                  <div class="input-group mx-0 position-relative small-input">
                    <span class="my-auto mx-auto text-gray">—</span>
                  </div>

                  <div class="input-group mr-0-5 position-relative small-input">
                    <select
                      v-model="formFields.end_hours"
                      v-validate="!activatedFullDay ? fieldValidationRule : ''"
                      :data-vv-as="$t('salon-days-off.form-label.date')"
                      :disabled="activatedFullDay"
                      name="end_hours"
                      class="form-control w-100 px-0-5 text-center"
                      style="font-size:14px;"
                    >
                      <option v-for="(option, key) in endHoursList" :key="`start_hours_${key}`" :value="option">{{
                        option
                      }}</option>
                    </select>
                  </div>
                  <div class="input-group mr-0-5 position-relative small-input">
                    <select
                      v-model="formFields.end_minutes"
                      v-validate="!activatedFullDay ? fieldValidationRule : ''"
                      :data-vv-as="$t('salon-days-off.form-label.date')"
                      :disabled="activatedFullDay"
                      name="end_minutes"
                      class="form-control w-100 px-0-5 text-center"
                      style="font-size:14px;"
                    >
                      <option v-for="(option, key) in endMinutetsList" :key="`start_hours_${key}`" :value="option">{{
                        option
                      }}</option>
                    </select>
                  </div>

                  <span
                    v-if="
                      errors.has('start_hours') ||
                        errors.has('start_minutes') ||
                        errors.has('end_hours') ||
                        errors.has('end_minutes')
                    "
                    class="error-message mt-0-5 mb-1-5 w-100"
                  >
                    {{
                      errors.first('start_hours') ||
                        errors.first('start_minutes') ||
                        errors.first('end_hours') ||
                        errors.first('end_minutes')
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
              {{ $t('salon-days-off.form-label.description') }}
            </label>
            <div class="input-group mb-1-5">
              <textarea
                v-model="formFields.description"
                v-validate="'max:255'"
                :data-vv-as="$t('salon-days-off.form-label.description')"
                name="description"
                class="w-100 border rounded"
                rows="10"
              ></textarea>
            </div>
            <span v-if="errors.has('description')" class="error-message mt-0-5 mb-1-5 w-100">
              {{ errors.first('description') }}
            </span>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import TimeConstants from '@/_constants/time.js'
import moment from 'moment/src/moment'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'SalonServicesForm',
  components: {
    PageNavigator
  },
  data() {
    return {
      now: moment().format('YYYY-MM-DD'),
      loadParams: false,
      serviceLoaded: false,
      submitProcess: false,
      activatedFullDay: true,
      createSuccessMessage: {
        type: this.$t('salon-days-off.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: this.$t('salon-days-off.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.updated'
      },
      deletedSuccessMessage: {
        type: this.$t('salon-days-off.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.deleted'
      },
      fieldValidationRule: 'required',
      hoursList: TimeConstants.HOURS_LIST,
      minutesList: TimeConstants.MINUTES_LIST,
      formFields: {
        title: '',
        staff: 0,
        start_date: '',
        start_hours: '',
        start_minutes: '',
        end_date: '',
        end_hours: '',
        end_minutes: '',
        description: '',
        date: ''
      },
      fullStaff: null
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      staffList: 'salonsStaff/getStaffList'
    }),
    weekendId() {
      return this.$route.params.id
    },
    formattedStaffList() {
      return this.staffList.filter(el => el.role === 'Staff')
    },
    dateList() {
      let dateStart = moment()
      let dateEnd = moment().add(1, 'M')
      let timeValues = []

      while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
        timeValues.push(dateStart.format('LL'))
        dateStart.add(1, 'day')
      }

      return timeValues
    },
    startHoursList() {
      return this.hoursList
    },
    endHoursList() {
      return this.hoursList.filter(hour => hour >= this.formFields.start_hours)
    },
    startMinutetsList() {
      let minMinuteStart = 0
      let maxMinuteEnd = 45
      let lastHourStart = this.startHoursList[this.startHoursList.length - 1]
      let lastHourEnd = this.hoursList[this.hoursList.length - 1]
      let firstHourEnd = this.hoursList[0]

      return this.calculateStartMinutes(
        this.formFields.start_hours,
        lastHourStart,
        lastHourEnd,
        firstHourEnd,
        minMinuteStart,
        maxMinuteEnd
      )
    },
    endMinutetsList() {
      let minMinuteStart = 0
      let maxMinuteEnd = 45
      let lastHourEnd = this.hoursList[this.hoursList.length - 1]

      return this.calculateEndMinutes(
        this.formFields.end_hours,
        this.formFields.start_hours,
        lastHourEnd,
        this.formFields.start_minutes,
        maxMinuteEnd
      )
    }
  },
  mounted() {
    if (this.accountInfo.salon_id) {
      this.getStaffListBySalonId(this.accountInfo.salon_id)
    }
    if (this.weekendId) {
      this.fetchDayOffDetailsById(this.weekendId).then(response => {
        const { id, title, description, staff_id, date, start_time, end_time } = response

        this.formFields.title = title
        this.formFields.description = description

        if (staff_id) {
          this.formFields.staff = staff_id
        }

        this.formFields.date = moment(date).format('LL')

        if (response.start_time) {
          this.activatedFullDay = false
          this.formFields.start_hours = this.calcHours(start_time)
          this.formFields.start_minutes = this.calcMinutes(start_time)
        }

        if (response.end_time) {
          this.activatedFullDay = false
          this.formFields.end_hours = this.calcHours(end_time)
          this.formFields.end_minutes = this.calcMinutes(end_time)
        }
      })
    }
  },
  methods: {
    ...mapActions({
      deleteSingleDayOffById: 'dayoffs/deleteSingleDayOffById',
      fetchDayOffDetailsById: 'dayoffs/getDayOffDetailsById',
      getStaffListBySalonId: 'salonsStaff/getStaffListBySalonId',
      createDayOff: 'dayoffs/createDayOff',
      updateDayOff: 'dayoffs/updateDayOff',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    submitForm() {
      this.submitProcess = true

      this.$validator.validateAll().then(result => {
        if (result) {
          let payload = {
            ...{ title: this.formFields.title },
            ...{ description: this.formFields.description },
            ...{ date: moment(this.formFields.date).format('YYYY-MM-DD') },
            ...{ start_time: null },
            ...{ end_time: null }
          }

          if (!this.activatedFullDay) {
            payload = {
              ...payload,
              ...{
                start_time: this.calcFullTime(this.formFields.start_hours, this.formFields.start_minutes)
              },
              ...{
                end_time: this.calcFullTime(this.formFields.end_hours, this.formFields.end_minutes)
              }
            }
          }
          if (this.formFields.staff && this.formFields.staff !== '0' && this.fullStaff) {
            if (this.fullStaff.staff_id) {
              payload = {
                ...payload,
                staff_id: this.fullStaff.staff_id
              }
            } else if (this.fullStaff.manager_id) {
              payload = {
                ...payload,
                manager_id: this.fullStaff.manager_id
              }
            }
          } else {
            payload = {
              ...payload,
              ...{ salon_id: this.accountInfo.salon_id }
            }
          }
          if (this.weekendId === undefined) {
            this.createDayOff(payload)
              .then(() => {
                this.setSuccess(this.createSuccessMessage)

                this.submitProcess = false

                this.$router.push('/salon/days-off')
              })
              .catch(() => (this.submitProcess = false))
          } else {
            payload = { ...payload, ...{ dayoff_id: this.weekendId } }

            this.updateDayOff(payload)
              .then(() => {
                this.setSuccess(this.updateSuccessMessage)

                this.submitProcess = false

                this.$router.push('/salon/days-off')
              })
              .catch(() => (this.submitProcess = false))
          }
        } else {
          this.submitProcess = false
        }
      })
    },
    choosenStaff(event) {
      if (event.target.value) {
        this.staffList.forEach(el => {
          if (el.staff_id && el.staff_id === Number(event.target.value)) this.fullStaff = el
          else if (el.manager_id && el.manager_id === Number(event.target.value)) this.fullStaff = el
        })
      }
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess() {
      this.deleteSingleDayOffById(this.weekendId).then(response => {
        this.setSuccess(this.deletedSuccessMessage)
        this.$router.push('/salon/days-off')
      })
    },
    deleteWeekend() {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess()
          }
        })
        .catch(error => {})
    },
    calcFullTime(hours, minutes) {
      if (hours === 0) {
        hours = '00'
      }

      return `${hours}:${minutes}`
    },
    calcMeridiem(time) {
      let hours = Number(time.split(':')[0])

      if (hours >= 12) {
        return 'PM'
      }

      return 'AM'
    },
    calcHours(time) {
      return Number(time.split(':')[0])
    },
    calcMinutes(time) {
      let minutes = Number(time.split(':')[1])

      if (minutes === 0) {
        minutes = '00'
      }

      return minutes
    },
    calculateStartMinutes(startHour, lastHourStart, lastHourEnd, firstHourEnd, minMinuteStart, maxMinuteEnd) {
      let minutes = []

      if (startHour === lastHourStart && startHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => Number(minute) <= maxMinuteEnd)
      } else if (startHour === firstHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) >= minMinuteStart)
      } else if (startHour === lastHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) <= maxMinuteEnd)
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    calculateEndMinutes(endHour, startHour, lastHourEnd, startMinute, maxMinuteEnd) {
      let minutes = []

      if (endHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => {
          if (startHour === lastHourEnd) {
            return Number(minute) >= startMinute && Number(minute) <= maxMinuteEnd
          } else {
            return Number(minute) <= maxMinuteEnd
          }
        })
      } else if (endHour === startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) > startMinute)
      } else {
        minutes = this.minutesList
      }

      return minutes
    }
  }
}
</script>
